@use 'sass:math';
@use '_mixin' as m;

/*----------
	top
----------*/
.l-content {
	background: none;
}

.top-kv {
	width: 100%;
	height: 100vh;
	@supports (height: 100svh){
		height: 100svh;
	}
	position: relative;
	overflow: hidden;

	&__image {
		position: relative;
		width: 100%;
		height: 100%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-position: right top;
			object-fit: contain;
		}
	}

	&__copy	{
		width: 100%;
		padding: 0 5.3%;
		position: absolute;
		left: 0;
		bottom: 50px;

		> em {
			display: block;
			max-width: 300px;
		}
		> span {
			display: block;
			margin-top: 20px;
			font-size: 1.2rem;
			line-height: 1;
			color: var(--color-text);
		}
	}

	@include m.mq-pc {
		&__copy	{
			padding: 0;
			left: 50px;
			bottom: 80px;

			> em {
				max-width: 524px;
			}
			> span {
				margin-top: 30px;
				font-size: 2rem;
			}
		}
	}

	@media (min-width: 768px) and (max-aspect-ratio: 3/2) {
		max-height: 800px;
	}
}


/* News */
.top-news-wrapper {
	padding-top: 90px;
	padding-bottom: 200px;
	background: url(/assets/images/bg-line-bottom.png) no-repeat left bottom / 200px auto;

	@include m.mq-pc {
		padding: 150px 0;
		background-size: 290px auto;
	}
}

.top-news {
	@include m.mq-pc {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		column-gap: 60px;
	}
}

.top-news-title {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	> h2 {
		font-weight: 500;
		font-family: var(--font-raj);
		font-size: 4.4rem;
		line-height: 1;
	}

	@include m.mq-pc {
		flex: 0 0 250px;
		display: block;

		> h2 {
			margin-bottom: 36px;
			font-size: 5.4rem;
		}
	}
}

.top-news-link {
	flex: 0 1 auto;

	@include m.mq-pc {
		display: inline-block;
	}
}

.top-news-list {
	margin-top: 60px;

	@include m.mq-pc {
		flex: 1 1 auto;
		margin-top: 0;
	}
}

.top-news-meetinglink {
	margin-top: 60px;
	padding: 20px;
	border-radius: 6px;
	background-color: #fff;

	> dt {
		font-weight: 500;
		font-size: 1.8rem;
		line-height: var(--line-height-m);
	}

	> dd {
		margin-top: 28px;
	}

	@include m.mq-sp {
		> dd {
			> div + div {
				margin-top: 20px;
			}
		}
	}

	@include m.mq-pc {
		> dd {
			display: flex;
			justify-content: space-between;
			gap: 0 18px;

			> div {
				flex: 1 1 50%;
			}
		}
	}
}


/* カテゴリーリンク */
.top-catelink-wrapper {
	padding-top: 100px;
	table-layout: 100px;
	background: url(/assets/images/bg-line-top.png) no-repeat right top / 200px auto;

	@include m.mq-pc {
		background-size: 230px auto;
	}
}


/* IR */
.top-featurelink-ir {
	margin-top: 120px;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(0,0,0,0.3);
	}

	&::after {
		background-color: var(--color-theme-blue);
	}

	&__title {
		width: auto;
		top: 34px;
		font-family: var(--font-raj);
		font-weight: 500;
		font-size: 6rem;
		line-height: 0.9;
	}

	&__text {
		top: 158px;
	}

	&__btn {
		bottom: 60px;

		> a {
			&::after {
				background-color: var(--color-theme-blue);
			}
		}
	}

	@include m.mq-pc {
		height: 450px;

		&__title {
			top: 96px;
			font-size: 9rem;
		}
		&__text {
			top: 226px;
		}
		&__btn {
			bottom: 140px;
		}
	}
}

.top-ir-link {
	@include m.inner-content;
	margin-top: -40px;
	position: relative;
	z-index: 5;

	> ul {
		background-color: #fff;
		border: 1px solid var(--color-border);
		border-radius: 6px;
		overflow: hidden;

		> li {
			> a {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				min-height: 78px;
				padding: 16px 56px 16px 72px;
				position: relative;
				text-decoration: none;
				font-weight: 500;
				font-size: 1.5rem;
				line-height: var(--line-height-s);
				color: var(--color-text);

				&::before {
					@include m.iconfont-default;
					content: map-get(m.$icons, 'chev-right');
					display: block;
					width: 12px;
					height: 12px;
					position: absolute;
					top: 50%;
					right: 20px;
					font-size: 12px;
					transform: translateY(-50%);
				}

				> i {
					display: block;
					width: 20px;
					height: 20px;
					position: absolute;
					top: 50%;
					left: 28px;
					font-size: 20px;
					transform: translateY(-50%);
					color: var(--color-theme-blue);
				}
			}

			+ li {
				border-top: 1px solid var(--color-border);
			}
		}
	}

	@include m.mq-pc {
		margin-top: -60px;

		> ul {
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			> li {
				border-right: 1px solid var(--color-border);

				&:nth-child(3n) {
					border-right: none;
				}

				> a {
					min-height: 118px;
					font-size: 1.9rem;

					> i {
						width: 24px;
						height: 24px;
						font-size: 24px;
					}
				}

			}
		}
	}
}


/* カテゴリーリンク2 */
.top-catelink2 {
	margin-top: 100px;

	@include m.mq-pc {
		margin-top: 150px;
	}
}
